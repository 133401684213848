 /* google fonts import from google */
 
 @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&family=Overpass:wght@300;400;500&display=swap');
 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     text-decoration: none;
     list-style-type: none;
     border: none;
     outline: none;
     background: transparent;
     font-family: 'Overpass', sans-serif;
     scroll-behavior: smooth;
 }
 
 :root {
     --main-clr: #767486;
     --medium-grey: rgb(37, 37, 37);
     --dark-purple: #131314;
     --heading-font: 'Kanit', sans-serif;
 }
 
 .btn {
     padding: .2rem 2rem !important;
     background-color: var(--main-clr);
     color: #fff !important;
     border-radius: 10px;
     transition: .5s;
     border: 2px solid transparent;
     font-size: 1.5rem;
 }
 
 .btn:hover {
     color: #fff !important;
     border: 2px solid #000;
 }
 
 nav {
     background: #000;
     text-align: center;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     width: 100%;
 }
 
 nav img {
     width: 11rem;
 }
 
 .main {
     background-position: center center;
     background-size: cover;
     height: 100vh;
     width: 100%;
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     margin: auto;
 }
 
 .content {
     margin: 2rem;
     border: 2px solid #fff;
     padding: 4rem 3rem;
     text-align: center;
     color: #fff;
     background: rgba(236, 236, 236, 0.253);
     backdrop-filter: blur(5px);
     border-radius: 10px;
 }
 
 .content h1 {
     font-family: var(--heading-font);
     margin-bottom: .8rem;
 }
 
 .content h2 {
     margin-bottom: 3rem;
     text-transform: uppercase;
     font-size: 1.3rem;
 }
 
 @media only screen and (max-width:720px) {
     .content {
         margin-top: 30%;
     }
     .content h1 {
         font-size: 1.5rem;
     }
     .content h2 {
         font-size: 1.2rem;
     }
     .btn {
         font-size: 1.2rem;
     }
 }