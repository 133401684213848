 /* google fonts import from google */
 
 @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&family=Overpass:wght@300;400;500&display=swap');
 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     text-decoration: none;
     list-style-type: none;
     border: none;
     outline: none;
     background: transparent;
     font-family: 'Overpass', sans-serif;
     scroll-behavior: smooth;
 }
 
 ::-webkit-scrollbar {
     width: 10px;
 }
 /* Track */
 
 ::-webkit-scrollbar-track {
     background: rgb(14, 13, 13);
     border-radius: 10px;
 }
 /* Handle */
 
 ::-webkit-scrollbar-thumb {
     background: var(--main-clr);
     border-radius: 10px;
 }
 /* Handle on hover */
 
 ::-webkit-scrollbar-thumb:hover {
     background-color: #494953;
 }
 
 :root {
     background-color: #0b0b0e;
     --main-clr: ;
     --medium-grey: rgb(37, 37, 37);
     --dark-purple: #131314;
     --heading-font: 'Kanit', sans-serif;
 }
 
 .btn {
     padding: .4rem 1rem !important;
     background-color: var(--main-clr);
     color: #fff !important;
     border-radius: 10px;
     transition: .5s;
     border: 2px solid transparent;
 }
 
 body {
     background-color: #0b0b0e;
 }
 
 .btn:hover {
     color: #fff !important;
     background: var(--dark-purple);
     border: 2px solid #fff;
 }
 
 .section-heading {
     font-size: 2rem;
     font-family: var(--heading-font);
     padding: 2rem 0;
     padding-top: 3rem;
     text-align: center;
     display: flex;
     margin: auto;
     align-items: center;
     justify-content: center;
 }
 
 .menu i {
     font-size: 2rem;
     color: #fff;
     display: none;
 }
 /* navigation menu starts from here */
 
 .navbar {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: .7rem 2rem;
     background: #0b0b0e;
     position: fixed;
     left: 0;
     right: 0;
     z-index: 3559458934;
 }
 
 .navbar .nav-left img {
     width: 10rem;
 }
 
 .navbar .nav-right .nav-links a {
     color: #fff;
     font-size: 1.1rem;
     font-weight: 500;
     padding: 0 .5rem;
     cursor: pointer;
     font-weight: 500;
     transition: .5s;
 }
 
 .navbar .nav-right .nav-links a:hover {
     color: var(--main-clr);
 }
 
 .sidebar-social {
     position: absolute;
     left: 0;
     z-index: 12344;
     display: grid;
     margin-left: 1rem;
     margin-top: 14.5rem;
     grid-template-columns: 1fr;
     position: fixed;
 }
 
 .sidebar-social i {
     font-size: 1.5rem;
     padding: .5rem;
     margin: .5rem 0;
     color: #fff;
     border-radius: 50%;
     transition: .5s;
 }
 
 .sidebar-social i:hover {
     color: #fff;
     transform: scale(1.1);
 }
 /* navigation menu ends here */
 /*          Header section design starts from here*/
 /* header section starts from here */
 
 .header {
     width: 100%;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 0 6rem;
     background: rgb(51, 0, 51);
     gap: 10rem;
     background: rgb(26, 24, 24);
     background-repeat: no-repeat;
     background-position: top right;
     background-size: 400%;
     overflow: hidden;
 }
 
 .header h1 {
     font-size: 3rem;
     color: white;
     font-family: var(--heading-font);
     padding: 2rem 0;
 }
 
 .header p {
     color: rgb(236, 236, 236);
     margin: 0 0 2rem 0;
 }
 
 .header .right img {
     margin-top: 30px;
     border-radius: 20px;
     width: 25rem;
     transform: rotateY(180deg);
 }
 /* header section enda here */
 /* Header section design ends here*/
 /* welcome section starts from here */
 
 .welcome {
     background: var(--medium-grey);
     color: #fff;
     padding: 2rem 6rem;
 }
 
 .welcome-dta {
     display: grid;
     grid-template-columns: 1fr 1fr;
     align-items: center;
     justify-content: center;
     gap: 2rem;
     padding-top: 2rem;
 }
 
 .welcome-dta .left {
     display: grid;
     grid-template-columns: 1fr 1fr;
 }
 
 .welcome-dta .left img {
     width: 100%;
 }
 
 .welcome-dta .right p {
     font-size: 1.3rem;
     color: grey;
     margin: 2.5rem;
     line-height: 2rem;
 }
 /* welcome section ends here */
 /* retire section starts from here */
 
 .retire {
     padding: 2rem 6rem;
     background: rgb(26, 24, 24);
     color: #fff;
 }
 
 .retire-dta {
     margin-top: 1rem;
     display: grid;
     grid-template-columns: 1fr 1fr;
     align-items: center;
     gap: 3rem;
 }
 
 .retire-dta .left p {
     font-size: 1.5rem;
     color: grey;
     line-height: 3rem;
 }
 
 .retire-dta .right img {
     width: 80%;
 }
 /* retire section ends here */
 /* The Goat Section starts from here */
 
 .goat {
     text-align: center;
     padding: 2rem 4rem;
     background: rgb(39, 37, 37);
     color: #fff;
 }
 
 .goat .section-heading {
     margin-bottom: 0;
     padding-bottom: 0;
 }
 
 .section-heading-detail {
     padding-bottom: 2rem;
 }
 
 .section-detail {
     color: grey;
     font-size: 1.2rem;
     padding-bottom: 2rem;
 }
 
 .goat .images {
     width: 100%;
     overflow-y: scroll;
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
     height: 16rem;
     gap: 1rem;
 }
 
 .goat .images .img {
     margin: 2rem 0;
 }
 
 .goat .images .img img {
     width: 100%;
 }
 /* The Goat Section ends here */
 /* roadmap section starts from here */
 
 .roadmap {
     padding: 2rem 8rem;
     background: var(--dark-purple);
     color: #fff;
     background: linear-gradient(to bottom, rgba(119, 116, 116, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 251, 251, 0.4) 0%, rgba(0, 0, 0, 0.40) 120%) #272626;
     background-blend-mode: multiply, multiply;
     background-position: center center;
     background-repeat: no-repeat;
     background-size: cover;
 }
 
 .roadmap .section-heading {
     margin-bottom: 0;
     padding-bottom: 0;
     font-family: var(--heading-font);
 }
 
 .roadmap .section-heading-detail {
     text-align: center;
     font-size: 1.1rem;
     margin-bottom: 3rem;
     font-family: var(--heading-font);
 }
 
 .roadmap-plan {
     display: flex;
     align-items: center;
     gap: 4rem;
     margin: 3rem 0;
 }
 
 .roadmap-plan .left {
     font-size: 1.5rem;
     font-weight: bolder;
     font-family: var(--heading-font);
     padding: 1rem 2rem;
 }
 
 .plan-name {
     font-family: var(--heading-font);
     font-size: 1.5rem;
     padding-bottom: 1rem;
 }
 
 .plan-detail {
     color: rgb(151, 148, 148);
     font-size: 1.2rem;
     line-height: 1.8rem;
 }
 /* roadmap section ends here */
 /* Team section starts from here  */
 
 .team {
     padding: 2rem 6rem;
     background: var(--dark-purple);
     color: #fff;
 }
 
 .team-detail {
     color: rgb(155, 151, 151);
     font-size: 1.3rem;
     line-height: 2rem;
     margin-bottom: 1rem;
 }
 
 .team-members {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     gap: 2rem;
     margin-top: 4rem;
     align-items: center;
     text-align: center;
 }
 
 .team-member img {
     width: 100%;
     height: 21rem;
     transition: .5s;
 }
 
 .team-member img:hover {
     border-radius: 20px;
     transform: scale(1.1);
 }
 
 .team-member {
     transition: .5s;
     border-radius: 20px;
     padding: 0 0 1rem 0;
     overflow: hidden;
 }
 
 .team-member:hover {
     box-shadow: 0 0 10px rgb(77, 75, 75);
 }
 
 .member-name {
     font-size: 1.3rem;
     margin-top: 1rem;
     color: rgb(224, 222, 222);
 }
 
 .member-position {
     font-size: 1.1rem;
     color: grey;
 }
 /* Team section ends here */
 /* footer section starts from here */
 
 .footer {
     padding: 2rem 6rem;
     padding-top: 1rem;
     background-position: center center;
     background-size: cover;
     color: #fff;
 }
 
 .social-items {
     text-align: center;
 }
 
 .social-items i {
     font-size: 2rem;
     margin: 0 1rem;
     color: var(--main-clr);
     transition: .5s;
 }
 
 .social-items i:hover {
     color: #fff;
     transform: rotate(360deg);
 }
 
 .copyright {
     margin-top: 2rem;
     text-align: center;
     font-size: 1.2rem;
 }
 /* footer section ends here */
 
 :root {
     --main-clr: #767486;
     --medium-grey: rgb(37, 37, 37);
     --dark-purple: #131314;
     --heading-font: 'Kanit', sans-serif;
 }
 
 ::-webkit-scrollbar {
     width: 10px;
 }
 /* Track */
 
 ::-webkit-scrollbar-track {
     background: rgb(14, 13, 13);
     border-radius: 10px;
 }
 /* Handle */
 
 ::-webkit-scrollbar-thumb {
     background: var(--main-clr);
     border-radius: 10px;
 }
 /* Handle on hover */
 
 ::-webkit-scrollbar-thumb:hover {
     background-color: #494953;
 }
 
 @media only screen and (max-width:1200px) {
     .header {
         gap: 2rem;
     }
 }
 
 @media only screen and (max-width:1000px) {
     .header .right img {
         width: 17rem;
         transform: rotateY(180deg);
     }
     .menu i {
         font-size: 2rem;
         display: unset;
     }
     .navbar {
         z-index: 9999;
     }
     .navbar .nav-right {
         position: absolute;
         display: flex;
         text-align: center;
         flex-direction: column;
         gap: 2rem;
         background: rgba(85, 85, 85, 0.219);
         backdrop-filter: blur(6px);
         padding: 5rem;
         height: 100vh;
         z-index: 666;
         left: 0;
         bottom: 0;
         transform: translateX(-100rem);
         transition: .5s;
     }
     .navbar .nav-right.active {
         transform: translateX(0rem);
     }
     .navbar .nav-right a {
         text-decoration: none;
         font-size: 1.2rem;
     }
     .team-members {
         padding: 0 2rem;
     }
     .faq-dta {
         padding: 2rem 0;
     }
 }
 
 @media only screen and (max-width:850px) {
     .header {
         flex-direction: column;
         padding-top: 5rem;
         height: auto;
         padding-bottom: 2rem;
         gap: 5rem;
     }
     .team-members {
         padding: 0;
     }
     .team {
         padding: 2rem;
     }
     .join {
         padding: 2rem;
     }
     .faq {
         padding: 2rem;
     }
     .our-nft {
         padding: 2rem;
     }
     .header {
         padding-left: 2rem;
         padding-right: 2rem;
     }
 }
 
 @media only screen and (max-width:990px) {
     .nav-right {
         position: absolute;
         right: 0;
         padding: 5rem;
         background: var(--dark-purple);
         height: 100vh;
         top: 0;
         z-index: 2343;
         margin-top: 5rem;
         display: none;
     }
     .nav-right a {
         display: grid;
         grid-template-columns: 1fr;
         margin: 1rem 0;
     }
     .menu i {
         display: unset;
     }
     .welcome-dta {
         grid-template-columns: 1fr;
         gap: 2rem;
     }
     .retire-dta {
         grid-template-columns: 1fr;
         gap: 3rem;
         align-items: center;
         text-align: center;
     }
     .goat .images {
         grid-template-columns: 1fr 1fr 1fr;
     }
     .team-members {
         grid-template-columns: 1fr 1fr;
     }
 }
 
 @media only screen and (max-width:600px) {
     .navbar {
         padding: .7rem 2rem;
     }
     .header .btn {
         font-size: 1rem;
     }
     .roadmap {
         padding: 2rem;
     }
     .team {
         padding: 2rem;
     }
     .goat {
         padding: 2rem;
     }
     .retire {
         padding: 2rem;
     }
     .welcome {
         padding: 2rem;
     }
     .welcome-dta .right p {
         margin: 2.5rem 0;
     }
     .retire-dta .left p {
         line-height: 1.5rem;
         font-size: 1.2rem;
     }
     .sidebar-social {
         margin-left: .5rem;
     }
     .sidebar-social i {
         display: none;
         font-size: 1.1rem;
         margin: 0;
     }
     h1 {
         font-size: 2.5rem !important;
     }
     h3 {
         font-size: .9rem !important;
     }
     .plan-name {
         font-size: 2rem;
     }
 }
 
 @media only screen and (max-width:450px) {
     .team-members {
         grid-template-columns: 1fr;
     }
     .team-member img {
         height: 30rem;
     }
     .roadmap-plan .left {
         padding: 0rem;
         display: none;
         font-size: 1.1rem;
     }
     p {
         font-size: 1.1rem !important;
         line-height: 1.3rem !important;
     }
     .goat .images {
         grid-template-columns: 1fr 1fr;
     }
     .team-member img {
         height: 23rem;
     }
     .footer {
         padding: 2rem;
     }
     .navbar .nav-left img {
         width: 10rem;
     }
 }
 
 @media only screen and (max-width:750px) {
     .sidebar-social i {
         display: none;
         font-size: 1.1rem;
         margin: 0;
     }
 }